// import { defineStore } from "pinia";
// import { ref } from "vue";

export const usePreviewModeStore = defineStore(
  "previewModeStore",
  () => {
    const previewMode = ref(false);

    const setPreviewMode = (state: boolean) => {
      previewMode.value = state;
    };

    return { setPreviewMode, previewMode };
  },
  {
    persist: true,
  }
);
